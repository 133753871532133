import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import {Deutsch, English, Type, LinkWORK, LinkDE, LinkEN, Page, SiteTitle} from '../components/linksetc.js'
import Typist from 'react-typist'

const AboutPage = ( {location} ) => (




  <Layout>


    <SiteTitle string="About" />


      <Page location={location}>


          <English Language={location.search}>
                <Type>
                I am a freelance copywriter for marketing and branding based in Zürich, Switzerland. My strengths are SEO optimization and content for websites, and communicating complex products (b2b) in areas such as IT, industry, logistics, finance or pharma. In English and <LinkDE location={location}>German</LinkDE>.

                <p/>I often work with design-focused agencies,
                linking visual ideas to the strategic communication goals of a brand or creating verbal identities.

                <p/>
                Here are my <LinkWORK location={location}>portfolio projects</LinkWORK> and <Link to="/angebot">the services I offer</Link>. 

                <Typist.Backspace count={528} delay={62000}  />


                <Typist.Delay ms={5000} />
                You are still here?
                <Typist.Delay ms={2000} />
                <p/>Did you leave this tab open or something?

                <Typist.Delay ms={1000} />
                <p/>Since you seem to have a lot of time on your hands...
                <Typist.Delay ms={3000} />
                <p/>I feel kinda lazy today. Why don't you write <Link to="/guestbook?en">your own text.</Link>
                <p/>Or if you're also feeling lazy, <Link to="/buzzwords-app?en">use my generator.</Link>


                </Type>
          </English>


         <Deutsch Language={location.search}>
              <Type>
                Ich arbeite als freiberuflicher Texter für Marketing und Branding in Zürich, Schweiz. Meine besonderen Stärken sind SEO Optimierung und UX writing für Webseiten, und das Vermitteln von komplexen Produkten (b2b-Kommunikation) in Bereichen wie IT, Industrie, Logistik, Finanzen oder Pharma. Auf <LinkEN location={location}>Englisch</LinkEN> und Deutsch.

                <p/>Ich arbeite oft mit Design-starken Agenturen,
                um ihre visuellen Ideen mit strategischen Kommunikationszielen eines Brands zu verbinden oder um passende verbale Identitäten zu erschaffen.

                <p/>
                Mehr zu meiner Arbeitsweise in meinen <LinkWORK location={location}>Portfolio-Projekten</LinkWORK> und in meinem <Link to="/angebot">detaillierten Angebot</Link>. 

                <Typist.Backspace count={528} delay={62000}  />


                <Typist.Delay ms={5000} />
                Du bist immer noch hier?
                <Typist.Delay ms={2000} />
                <p/>Hast du dieses Tab offen gelassen?
                <Typist.Delay ms={1000} />
                <p/>Weisst du was?

                <Typist.Delay ms={3000} />
                <p/>Da du viel Zeit zu haben scheinst: Schreib <Link to="/guestbook">deinen Text selber.</Link>
                <p/>Oder wenn du auch keine Lust hast, <Link to="/buzzwords-app">benutze diesen Generator.</Link>
              </Type>
        </Deutsch>



      </Page>

  </Layout>


)

export default AboutPage
