import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'





import './basic.css'



const Layout = ({ children }) => (

      <>

        {children}

      </>

)


export default Layout
